import React from "react"
import Layout from "../components/Layout"
import Forgotten from "../components/Dash/Forgotten"

const Index = () => (
  <Layout>
    <Forgotten />
  </Layout>
)

export default Index

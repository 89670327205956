import React from "react"
import ForgottenForm from "./ForgottenForm"
import View from "./View"
import axios from 'axios';
import { isBrowser } from './utils/Functions';
const website = require('../../../theme/config');

class Forgotten extends React.Component {
  state = {
    email: ``,
    message: ``,
    loading: ``
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
      message: '',
    })
  }

  handleSubmit(event) {
    const { email } = event.target
    event.preventDefault()
    this.setState({loading: true, message: ''});

    const handleForgotten = () => {
      if (!isBrowser) return false
      const url = `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/users/lost-password`
      return axios({
        method: 'post',
        url: url,
        data: {
          user_login: email.value,
          redirect_to: process.env.GATSBY_APP_ADDRESS + '/login/',
          logo: process.env.GATSBY_APP_ADDRESS + '/logos/vitahealth.png'
        },
        config: { headers: {'Content-Type': 'application/json' }}
      })
    }

    handleForgotten()
    .then( response => {
      this.setState({loading: false, message: 'Email has been sent!'});
      setTimeout(() => {
        this.setState({message: ''});
      }, 5000);
    })
    .catch(error => {
      console.log(error)
      this.setState({loading: false, message: 'We were unable to find a user with that email address.'});
      setTimeout(() => {
        this.setState({message: ''});
      }, 5000);
    })
  }

  render() {
    return (
      <div className="secure">
        <View title="Forgotten">
          <ForgottenForm
            handleUpdate={e => this.handleUpdate(e)}
            handleSubmit={e => this.handleSubmit(e)}
            handleChange={this.state}
          />
        </View>
      </div>
    )
  }
}

export default Forgotten

module.exports = {
  pwaName: 'Vitahealth', // Default Site Title used for PWA
  pwaShortName: 'Vitahealth', // shortname for manifest. MUST be shorter than 12 characters
  pwaDescription: 'Vitahealth',
  pwaBackgroundColor: '#FFF',
  pwaThemeColour:'#000000',
  pwaIcon: 'theme/favicon.png',
  googleTagManagerId: 'GTM-T4Q9ST9',
  zendeskKey: '5DrDfs8XodTZG6OohR43s2zcGPEDAQiO',
};
